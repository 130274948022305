<template>
	<div class="my-container">
		<div class="scroll-container">
			<div class="main-container">
				<div class="left">
					<div class="user-wrapper">
						<div class="icon-wrapper"><img class="avatar" :src="userInfo.avatar || defaultAvatar" alt="" /></div>
						<p class="name">{{ userInfo.name || '默小克' }}</p>
						<div class="change-icon">
							<input class="input-file" @change="updateAvatar" type="file" accept="image/*" />
							修改头像
						</div>
					</div>
					<ul :class="['follow-list', { active: isReset }]">
						<li>
							<div class="title">
								<span>我关注的产品</span>
								<span class="arrow" :class="{ top: !showProduct }" @click="showProduct = !showProduct"></span>
							</div>
							<ul class="follow-item" v-show="showProduct">
								<li v-for="(item, index) of currProductList" :class="{ active: isReset && item.checked }" @click="handleToggleActive(index, 'p')" :key="item.id">
									{{ item.name }}
								</li>
							</ul>
						</li>
						<li>
							<div class="title">
								<span>我关注的领域</span>
								<span class="arrow" :class="{ top: !showArea }" @click="showArea = !showArea"></span>
							</div>
							<ul class="follow-item" v-show="showArea">
								<li v-for="(item, index) of currRealmList" :class="{ active: isReset && item.checked }" @click="handleToggleActive(index, 'a')" :key="item.id">
									{{ item.name }}
								</li>
							</ul>
						</li>
						<li>
							<div class="title">
								<span>我感兴趣的话题</span>
								<span class="arrow" :class="{ top: !showTopic }" @click="showTopic = !showTopic"></span>
							</div>
							<ul class="follow-item" v-show="showTopic">
								<li v-for="(item, index) of currTopicList" :class="{ active: isReset && item.checked }" @click="handleToggleActive(index, 't')" :key="item.id">
									{{ item.name }}
								</li>
							</ul>
						</li>
					</ul>

					<div class="reset-btn" v-if="isReset" @click="saveTag">保存</div>
					<div class="reset-btn" v-else @click="resetTag">重置</div>
				</div>
				<div class="right">
					<ul class="top" v-if="userInfo && userInfo.type == 0">
						<li>
							<img src="./images/phone.png" alt="" class="icon" />
							<span>手机号：</span>
							<span>{{ userInfo && userInfo.phone }}</span>
						</li>
						<li>
							<img src="./images/hospital.png" alt="" class="icon" />
							<span>医院：</span>
							<span>{{ (userInfo && userInfo.hospital) || '无' }}</span>
						</li>
						<li>
							<img src="./images/section.png" alt="" class="icon" />
							<span>科室：</span>
							<span>{{ (userInfo && userInfo.department) || '无' }}</span>
						</li>
						<!-- <li>
							<img src="./images/job-title.png" alt="" class="icon">
							<span>职称：</span>
							<span>{{userInfo && userInfo.title || '无'}}</span>
						</li> -->
						<li>
							<img src="./images/city.png" alt="" class="icon" />
							<span>所在城市：</span>
							<span>{{ (userInfo && userInfo.province) || '无' }}{{ (userInfo && userInfo.city) || '' }}</span>
						</li>
					</ul>
          <ul class="top top_two" v-if="userInfo && userInfo.type == 1">
						<li>
							<img src="./images/phone.png" alt="" class="icon" />
							<span>手机号：</span>
							<span>{{ userInfo && userInfo.phone }}</span>
						</li>
						<li>
							<img src="./images/baogao.png" alt="" class="icon" />
							<span>muid：</span>
							<span>{{ (userInfo && userInfo.muid) || '无' }}</span>
						</li>
					</ul>
          <ul class="top top_two" v-if="userInfo && userInfo.type == 2">
						<li>
							<img src="./images/phone.png" alt="" class="icon" />
							<span>手机号：</span>
							<span>{{ userInfo && userInfo.phone }}</span>
						</li>
						<li>
							<img src="./images/baogao.png" alt="" class="icon" />
							<span>xuid：</span>
							<span>{{ (userInfo && userInfo.xuid) || '无' }}</span>
						</li>
					</ul>
          <ul class="top" v-if="userInfo && userInfo.type == 3">
						<li>
							<img src="./images/phone.png" alt="" class="icon" />
							<span>手机号：</span>
							<span>{{ userInfo && userInfo.phone }}</span>
						</li>
						<li>
							<img src="./images/hospital.png" alt="" class="icon" />
							<span>药店：</span>
							<span>{{ userInfo && userInfo.hospital }}</span>
						</li>
						<li>
							<img src="./images/city.png" alt="" class="icon" />
							<span>省份：</span>
							<span>{{ (userInfo && userInfo.province) || '无' }}</span>
						</li>
						<li>
							<img src="./images/city.png" alt="" class="icon" />
							<span>所在城市：</span>
							<span>{{ (userInfo && userInfo.city) || '无' }}</span>
						</li>
					</ul>
					<div class="content">
						<ul class="title">
							<li class="item" :class="{ active: activeItem === 'meeting' }" @click="handleToggleTab('meeting')">我的会议</li>
							<li class="line"></li>
							<li class="item" :class="{ active: activeItem === 'collection' }" @click="handleToggleTab('collection')">我的收藏</li>
							<!-- <li class="line"></li> -->
							<!-- <li class="item" :class="{active: activeItem === 'selected'}" @click="handleToggleTab('selected')"><img src="./images/hot-selected.png" class="icon" alt="">每周精选</li> -->
						</ul>
						<meeting v-if="activeItem === 'meeting'" :data="meetingList"></meeting>
						<collection v-if="activeItem === 'collection'"></collection>
						<selected v-if="activeItem === 'selected'"></selected>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Meeting from './meeting';
import Collection from './collection';
import Selected from './selected';
import indexApi from '@/api/index';
export default {
	name: 'myPage',
	components: { Selected, Collection, Meeting },
	data() {
		return {
			defaultAvatar: require('./images/avatar.png'),
			tagInfo: [],
			userInfo: {
				avatar: this.$store.state.userAvatar,
				name: this.$store.state.userName,
				phone: '',
				hospital: '',
				department: '',
				title: '',
				area: ''
			},
			showProduct: true,
			showArea: true,
			showTopic: true,
			curActive: '',
			activeItem: 'meeting',
			meetingList: [],

			currRealmList: [],
			currProductList: [],
			currTopicList: [],
			isReset: false
		};
	},
	created() {
		this.getUserInfo({ type: 0 });
		let type = this.$route.query.type;
		if (type) {
			this.activeItem = type;
		} else {
			this.activeItem = 'meeting';
		}
	},
	mounted() {
		this.getMeetingData();
	},
	methods: {
		handleToggleActive(index, o) {
			if (this.isReset) {
				if (o == 'p') {
					this.$set(this.currProductList[index], 'checked', !this.currProductList[index].checked);
				} else if (o == 'a') {
					this.$set(this.currRealmList[index], 'checked', !this.currRealmList[index].checked);
				} else if (o == 't') {
					this.$set(this.currTopicList[index], 'checked', !this.currTopicList[index].checked);
				}
			}
		},
		handleToggleTab(n) {
			this.activeItem = n;
		},
		getMeetingData() {
			const data = [];
			for (let i = 0; i < 3; i++) {
				data.push({
					id: i,
					src: './images/m-' + (i + 3) + '.png',
					title: ['2020默克论坛-分子分析诊断甲状腺结节的现状与未来', '2020默克论坛--甲状腺癌诊治前沿和热点', '2020默克论坛-甲状腺激素处方有何细微差别？'][i],
					product: '甲状腺疾病-优甲乐',
					time: '2021年07月20日',
					type: i === 1 ? 2 : 1,
					speakerList: [
						{
							id: 1,
							avatar: './images/a-1.png',
							name: '李华'
						},
						{
							id: 2,
							avatar: './images/a-2.png',
							name: '王小明'
						}
					]
				});
			}
			this.meetingList = data;
		},

		// 个人中心数据
		getUserInfo(params) {
			let that = this;
			indexApi.getUserInfo(params).then(
				res => {
					if (res && res.code === 1000) {
						that.$point.userIdentity(res, that, data => {
							console.log(data, '000000000000000000000000000000000000000000000000000');
							that.userInfo = data;
							that.currProductList = data.tagInfo.productList;
							that.currRealmList = data.tagInfo.realmList;
							that.currTopicList = data.tagInfo.topicList;
							that.getSelectTagList();
							that.$store.commit('setUserName', data.name);
							that.$store.commit('setUserAvatar', data.avatar);
						});
					} else {
						that.$message.error(res.message);
					}
				},
				err => {
					that.$message.success((err && err.message) || '获取失败，请联系管理员！');
				}
			);
		},

		resetTag() {
			this.isReset = true;
			this.currProductList = this.productList;
			this.currRealmList = this.realmList;
			this.currTopicList = this.topicList;
		},
		//全部标签
		getSelectTagList() {
			let params = {};
			indexApi.getSelectTagList(params).then(
				res => {
					if (res && res.code === 1000) {
						let data = res && res.data;
						let arr1 = data.realmList,
							arr2 = data.productList,
							arr3 = data.topicList;
						for (var i = 0; i < data.realmList.length; i++) {
							for (var j = 0; j < this.currRealmList.length; j++) {
								if (data.realmList[i].id == this.currRealmList[j].id) {
									arr1[i].checked = true;
								}
							}
						}
						for (var i = 0; i < data.productList.length; i++) {
							for (var j = 0; j < this.currProductList.length; j++) {
								if (data.productList[i].id == this.currProductList[j].id) {
									arr2[i].checked = true;
								}
							}
						}
						for (var i = 0; i < data.topicList.length; i++) {
							for (var j = 0; j < this.currTopicList.length; j++) {
								if (data.topicList[i].id == this.currTopicList[j].id) {
									arr3[i].checked = true;
								}
							}
						}
						this.realmList = arr1;
						this.productList = arr2;
						this.topicList = arr3;

						console.log('this.realmList', this.realmList, this.productList, this.topicList);
					}
				},
				err => {
					this.$message.success((err && err.message) || '获取失败，请联系管理员！');
				}
			);
		},

		// 保存标签
		saveTag() {
			const pList = this.currProductList.filter(item => item.checked);
			const aList = this.currRealmList.filter(item => item.checked);
			const tList = this.currTopicList.filter(item => item.checked);

			let _this = this;

			if (aList.length <= 0) {
				this.$message.error('请选择领域！');
				return;
			}

			let productIdList = [];
			pList.map(item => {
				productIdList.push(item.id);
			});

			let realmIdList = [];
			aList.map(item => {
				realmIdList.push(item.id);
			});

			let topicIdList = [];
			tList.map(item => {
				topicIdList.push(item.id);
			});
			let params = {
				productIdList: productIdList,
				realmIdList: realmIdList,
				topicIdList: topicIdList
			};
			indexApi.saveTag(params).then(
				res => {
					if (res && res.code === 1000) {
						this.$message.success('保存成功！');
						this.isReset = false;
						this.currProductList = pList;
						this.currRealmList = aList;
						this.currTopicList = tList;
					}
				},
				err => {
					this.$message.error((err && err.message) || '保存失败，请联系管理员！');
				}
			);
		},
		updateAvatar(data) {
			let file = data && data.target && data.target.files && data.target.files[0];
			let formData = new FormData();
			formData.append('file', file);
			// let params = {
			//   file: file
			// };
			indexApi.updateAvatar(formData).then(
				res => {
					if (res && res.code === 1000) {
						this.getUserInfo({ type: 0 });
						// this.$message.success('发送成功')
					}
				},
				err => {
					this.$message.success((err && err.message) || '获取失败，请联系管理员！');
				}
			);
		}
	}
}
</script>

<style scoped lang="scss">
.my-container {
	background: url('~./images/bg.png') top center/ 100% 2.9rem #ecf0f5 no-repeat;
}
.scroll-container {
	width: 100%;
	max-width: 100%;
	padding: 0.12rem 0 0.27rem;
}
.main-container {
	display: flex;
}
.left {
	margin-right: 0.2rem;
	width: 2.6rem;
	flex: none;
	.reset-btn {
		width: 1.2rem;
		height: 0.4rem;
		background-color: #eb3c96;
		border-radius: 0.2rem;
		font-size: 0.16rem;
		line-height: 0.4rem;
		margin: 0 auto;
		position: relative;
		cursor: pointer;
		text-align: center;
		color: #fff;
	}
	.user-wrapper {
		text-align: center;
		color: #fff;
		margin-bottom: 0.3rem;
		.icon-wrapper {
			padding: 0.14rem;
			width: 1.28rem;
			height: 1.28rem;
			margin: 0 auto;
			background: url('~./images/avatar-outline.png') top center / 100% auto no-repeat;
			border-radius: 100%;
			.avatar {
				display: block;
				width: 1rem;
				height: 1rem;
				border-radius: 100%;
			}
		}
		.name {
			font-size: 0.24rem;
			margin-bottom: 0.14rem;
			font-weight: bold;
		}
		.change-icon {
			width: 1.8rem;
			height: 0.4rem;
			background-color: #eb3c96;
			border-radius: 0.2rem;
			font-size: 0.16rem;
			line-height: 0.4rem;
			margin: 0 auto;
			position: relative;
			cursor: pointer;
			.input-file {
				width: 100%;
				height: 100%;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
			}
		}
	}
	.follow-list {
		user-select: none;
		> li {
			border-bottom: 1px solid #d3d6db;
			margin-bottom: 0.2rem;
			padding-bottom: 0.1rem;
			&:last-child {
				border: none;
			}
		}
		.title {
			font-size: 0.18rem;
			color: #1a1a1a;
			display: flex;
			justify-content: space-between;
			margin-bottom: 0.15rem;
			.arrow {
				width: 0.18rem;
				height: 0.18rem;
				background: url('~./images/arrow.png') center center / 0.14rem 0.08rem no-repeat;
				cursor: pointer;
				transition: all 0.3s;
				&.top {
					transform: rotate(180deg);
				}
			}
		}
	}
	.follow-item {
		display: flex;
		flex-wrap: wrap;
		li {
			white-space: nowrap;
			background: #fff;
			border-radius: 0.04rem;
			height: 0.28rem;
			line-height: 0.28rem;
			padding: 0 0.18rem;
			color: #333;
			font-size: 0.16rem;
			margin-right: 0.1rem;
			margin-bottom: 0.1rem;
			cursor: default;
			transition: all 0.3s;
			&.active {
				background-color: rgba(235, 60, 150, 0.1);
				color: #eb3c96;
			}
		}
	}
}
.follow-list.active {
	.follow-item li {
		cursor: pointer;
	}
}
.right {
	flex: auto;
	overflow-x: hidden;
	.top {
		padding: 0.52rem 0.22rem 0;
		margin-bottom: 0.52rem;
		color: #fff;
		font-size: 0.14rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		li {
			// display: flex;
			align-items: center;
			span {
				// min-width: .4rem;
			}
			.icon {
				height: 0.21rem;
				margin-right: 0.1rem;
				display: inline-block;
				vertical-align: middle;
				margin-top: -2px;
			}
		}
	}
	.content {
		background: #fff;
		border-radius: 0.14rem;
		padding: 0.3rem 0 0.3rem 0.4rem;
		.title {
			display: flex;
			align-items: center;
			margin-bottom: 0.2rem;
			.line {
				width: 1px;
				height: 0.1rem;
				background-color: #d2d4d6;
				align-self: flex-start;
				margin: 0.08rem 0.3rem 0;
			}
			.item {
				display: flex;
				align-items: center;
				padding-bottom: 0.17rem;
				color: #1a1a1a;
				font-size: 0.18rem;
				border-bottom: 1px solid transparent;
				cursor: pointer;
				transition: all 0.3s;
				&.active {
					color: #503291;
					border-color: #503291;
					font-weight: bold;
				}
				.icon {
					width: 0.16rem;
					height: 0.14rem;
					display: block;
					margin-right: 0.07rem;
				}
			}
		}
	}
}
.top_two{
  justify-content: flex-start !important;
  li+li{
    margin-left: 11%;
  }
}
</style>
