<template>
  <div class="meeting-wrap">
    <ul class="list" v-if="meetingList.length" @scroll="scroll">
      <li class="item" v-for="(item) in meetingList" :key="item.id" @click="getLivePath(item)">
        <!-- <a :href="item.path"  target="_blank"> -->
        <img :src="item.coverImg || '/system/file/merck/1350049990842855425' " alt="" class="img">
        <div class="content">
          <h3 class="title ellipsis" :title="item.title">{{ item.title }}</h3>
          <div class="top">
            <p class="left">
              <img src="./images/product.png" class="icon" alt="">
              <span class="name">产品：</span>
               <el-tooltip effect="dark" :content="item.tagList.length>0 ? item.tagList.join(',') :'--'" placement="top-start">
                  <span class="content contxt">
                  {{ item.tagList.length>0 ? item.tagList.join(',') :'--' }}
                  </span>
                  </el-tooltip>
            </p>
            <p class="left">
              <img src="./images/time.png" class="icon" alt="">
              <span class="name">时间：</span>
              
                <el-tooltip effect="dark" :content="item.liveTime" placement="top-start">
                  <span class="content contxt">
                  {{ item.liveTime }}
                  </span>
                  </el-tooltip>

            </p>
             <p class="left">
              <img src="./images/speaker.png" class="icon" alt="">
              <span class="name">角色：</span>
              <span class="content">{{item.attendType | attendTypeFilter}}</span>
            </p>
             <p class="right">
              <img src="./images/product.png" class="icon" alt="">
              <span class="name">会议形式：</span>
              <span class="content">{{item.memberSigninType | memberTypeFilter}}</span>
            </p>
          </div>
          <div class="bottom">
            <div class="speaker">
              <img src="./images/speaker.png" class="icon" alt="">
               <span class="name">讲者：</span>
            </div>
            <div class="avatar-box" style="flex:1">
               <span class="avatar-wrapper" v-for="n of item.lecturerList" :key="n.id">
                <!-- <img :src="n.avatar" alt="" class="avatar"> -->
                <img :src="n.avatar || defaultImg" alt="" class="avatar">
                <span class="speaker-name">{{ n.name }}</span>
              </span>
            </div>
           
          </div>
          <span class="btn" :class="{playing: item.status === 0}">{{item.statusText}}</span>
        </div>
        <!-- </a> -->
      </li>
    </ul>
    <div class="list empty-box" v-else>
            暂无会议
    </div>
    <div class="linGradual"></div>

    <!-- 会议链接弹框 -->
    <!-- <MeetModel v-if="onlineDialogVisible" :meetingInfo="meetingInfo" :visible.sync='onlineDialogVisible' @close='onlineDialogVisible = false' /> -->
  </div>
  
</template>

<script>
import indexApi from '@/api/index'
import config from '../conference-hall/config'
// import MeetModel from '@/components/site/model'
export default {
  // components: {MeetModel},
  name: "meeting",
  data() {
    return{
      meetingList: [],
      statusList: config.meetStatusList,
      defaultImg: require('./images/avatar-normal.png'),
      onlineDialogVisible:false,
      meetingInfo:{},
      hasMore:false,
      page:'1',
      loading:false
    }
  },
  props: {
    data: Array,
  },
  created() {
    this.getLiveMyList();
  },
  mounted() {},
  methods: {
    // 获取我的会议
    getLiveMyList() {
      let params = {
        // phone: this.form.phone
        "page": this.page,
        "size": 10
      };
      this.loading = true;
      indexApi.getLiveMyList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list || [];
          this.meetingList =  this.meetingList.concat(data);

          this.meetingList.map((item)=>{
            let statusObj = this.statusList.find((every)=>{
              return every.value === item.status;
            })

            item.statusText = statusObj && statusObj.label || '暂无状态';
          })
          if(this.meetingList.length < res.data.total){
                this.page ++;
                this.hasMore = true;
                console.log('还有更多================')
          }else{
               this.hasMore = false;
          }
          this.loading = false;
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    getLivePath(item) {
      let params = {
        meetingId: item.id
      };
       if(item.meetingPattern == 'offline' || 
        (item.meetingPattern == 'online_and_offline'&&item.memberSigninType =='offline_participation')
        ){
            this.$message.error('当前线下会议，请您线下参会');
        }else{
          indexApi.getLivePath(params).then((res)=>{
            if(res && res.code === 1000){
                let data = res && res.data;
          clearInterval(this.$options.timer);
          this.$options.timer = null;
                window.open(data.url)
            }
          },(err)=>{
          
          })
        }
      },
    /**
     * 滑动到底部
     */
    scroll(e){
      console.log(e)
        const ele = e.srcElement ? e.srcElement : e.target
         // 监听滚动到div底部
        if (ele.scrollTop + ele.offsetHeight == ele.scrollHeight ) { 
          console.log( this.hasMore)
            if(this.hasMore){
                console.log('加载更多==============')
                this.getLiveMyList();
            }
        }
    },
  }
}
</script>

<style scoped lang="scss">
.meeting-wrap{
  position: relative;

  .empty-box{
      text-align: center;
      padding-top:1rem;
  }
}
.linGradual{
    width: 100%;
    height: 0.58rem;
    background: #fff;
    position: absolute;
    bottom: -0.21rem;
    right: 0;
    box-shadow: 0px -30px 30px 0px #FFFFFF;
}

.list{
      height: 5.85rem;
      overflow-x: hidden;
      overflow-y: scroll;
      padding:0 .4rem .5rem 0;
    }
.item {
  line-height: 1;
  display: flex;
  align-items: flex-start;
  margin-bottom: .4rem;
  
    a{
        width: 100%;
        display: flex;
        align-items: flex-start; 
    }
  .img {
    display: block;
    width: 2.6rem;
    height: 1.45rem;
    background-color: #ffffff;
    border-radius: .08rem;
    margin-right: .4rem;
    cursor: pointer;
  }
  
  .content {
    flex: auto;
    position: relative;
    .title {
      font-size: .22rem;
      color: #1a1a1a;
      font-weight: bold;
      max-width: 6rem;
      margin-top: .1rem;
      margin-bottom: .2rem;
    }

    .icon {
      height: .16rem;
      width: .16rem;
      margin-right: .09rem;
      display: block;
    }
    .name {
      font-size: .16rem;
      color: #b3b3b3;
    }
    .content {
      font-size: .16rem;
      color: #666;
    }
    .contxt{
      width: 1.3rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      cursor: pointer;
    }  
    .top {
      border-bottom: 1px solid #e1e5eb;
      padding-bottom: .2rem;
      display: flex;
      align-items: center;
      margin-bottom: .2rem;

      .left, .right {
        display: flex;
        align-items: center;
      }
      .left {
        margin-right: .6rem;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      
      .speaker{
        display: flex;
        align-items: center;
        width:0.7rem;
        padding-bottom: 0.1rem;
        .name{
              white-space: nowrap;
        }
      }
      .avatar-box{
        display: flex;
        flex-wrap: wrap;
      }
      .avatar-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 0.1rem;
        .avatar {
          width: .32rem;
          height: .32rem;
          border-radius: 100%;
          margin-right: .1rem;
        }
        .speaker-name {
              font-size: 0.16rem;
              color: #666;
              margin-right: 0.04rem;
              display: block;
              width: 0.6rem;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
        }
      }
    }
    .btn {
      display: block;
      cursor: pointer;
      width: 1.2rem;
      height: .4rem;
      background: #f2f2f2;
      border-radius: .08rem;
      text-align: center;
      line-height: .4rem;
      color: #666;
      position: absolute;
      right: 0;
      top: 0;
      font-size: .18rem;
      &.playing {
        text-align: left;
        padding-left: .2rem;
        line-height: .42rem;
        background: url("~./images/playing.png") center center /100% 100%;
        color: #eb3c96;
      }
    }
  }

}
</style>
