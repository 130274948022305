<template>
  <div class="selected">
    <ul class="top">
      <li :class="{active: active === 'a'}" @click="handleToggleTab('a')">上一期</li>
      <li :class="{active: active === 'b'}" @click="handleToggleTab('b')">本期</li>
    </ul>
    <ul class="content">
      <li v-for="item of data" :key="item.id">
        <img :src="item.src" class="img" alt="">
        <div class="right">
          <h3 class="title ellipsis" :title="item.title">{{item.title}}</h3>
          <p class="des">{{item.des}}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "selected",
  data() {
    return {
      active: 'a',
      data: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const data = [];
      for(let i = 0; i < 6; i++) {
        data.push({
          id: i,
          title: 'Cyrene能否成为有害溶剂更环保的替代品Cyrene能否成为有害溶剂更环保的替代品',
          src: './images/j.png',
          des: '许多工业领域的工艺仍然依赖传统有机溶剂。这就是为什么我们需要使用绿色化学来开发。',
          tagList: [
            {
              id: 1,
              name: '甲状腺疾病'
            }
          ]
        })
      }
      this.data = data;
    },
    handleToggleTab(n) {
      this.active = n;
      this.getData();
    }
  }
}
</script>

<style scoped lang="scss">
.selected {
  line-height: 1;
  .top {
    display: flex;
    align-items: center;
    margin-bottom: .4rem;
    
    li {
      width: .88rem;
      height: .28rem;
      line-height: .28rem;
      border-radius: .04rem;
      border: solid 1px #e5e5e5;
      color: #666;
      font-size: .14rem;
      text-align: center;
      margin-right: .1rem;
      cursor: pointer;
      transition: all .3s;
      &.active {
        background: #fdebf4;
        color: #eb3c96;
        border-color: #fdebf4;
      }
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    height: 5.85rem;
    overflow-x: hidden;
    overflow-y: scroll;
    li {
      width: calc(50% - .4rem);
      display: flex;
      align-items: flex-start;
      margin-bottom: .5rem;
      &:nth-child(odd) {
        margin-right: .8rem;
      }
    }
    .img {
      display: block;
      width: 1.7rem;
      height: 1rem;
      margin-right: .2rem;
      border-radius: .08rem;
    }
    .right {
      flex: auto;
      position: relative;
      .title {
        font-size: .22rem;
        color: #333;
        margin-bottom: .2rem;
        margin-top: .1rem;
        max-width: 3.6rem;
      }
      .des {
        font-size: .14rem;
        color: #666;
        line-height: 1.6;
        margin-bottom: .2rem;
      }
    }
  }
}
</style>
