<template>
  <div class="collection">
    <ul class="top">
      <li :class="{active: active === 'a'}" @click="handleToggleTab('a')">产品信息</li>
      <li :class="{active: active === 'b'}" @click="handleToggleTab('b')">活动日历</li>
      <li :class="{active: active === 'c'}" @click="handleToggleTab('c')">临床视角</li>
      <li :class="{active: active === 'd'}" @click="handleToggleTab('d')">动态资讯</li>
      <li :class="{active: active === 'e'}" @click="handleToggleTab('e')">产品动态</li>
    </ul>
    <ul class="content" v-if="dataList.length">
      <li v-for="item in dataList" :key="item.id" @click="goDetail(item.id,item.realmId,item.productIds)">
        <img v-if="active != 'd'" :src=" active == 'c' ? item.titleImg :  item.coverImg" class="img" alt="">
        <div class="right">
          <h3 class="title">{{ active == 'a' ? item.name : item.title }}</h3>
          <p class="des" v-if="item.info" v-html="item.info"></p>
          <p class="tag">
            <span class="t" v-if="active == 'b'">{{item.tag}}</span>
            <span class="t" v-else>{{item.realmName}}</span>
          </p>
          <p class="des bottom0" v-if="active == 'b'">活动时间：{{item.activityTime}}</p>
          <p class="des bottom0" v-if="active == 'b'">会议讲者：{{item.lecturer}}</p>
          <span class="star active" @click.stop="isCollection(item.id)">
                  取消
                </span>

                <!-- <span v-show="isClocActive" :class="['star', {'active':isClocActiveID == item.id}]" @click="isCollection(item.id)">
                  收藏
                </span> -->
          <!-- <span class="star"><img src="./images/star-active.png" class="icon" alt="">取消</span> -->
        </div>
      </li>
    </ul>
    <div class="content empty-box" v-else>
                暂无内容
    </div>
    <div class="linGradual"></div>
  </div>
</template>

<script>
import indexApi from '@/api/index'
export default {
  name: "collection",
  data() {
    return {
      active: 'a',
      dataList: [],
      isClocActive: false,
      clocText: '收藏',
      isClocActiveID:-1
    }
  },
  created() {
    this.getUserFollowList();
  },
  mounted() {
    
  },
  methods: {

    handleToggleTab(n) {
      this.active = n;
      this.dataList = [];
      if(n == 'a') {
        this.getUserFollowList();
      } else if(n == 'b') {
        this.getActivityFollowList();
      } else if(n == 'c') {
        this.getPerspectiveFollowList();
      } else if(n == 'd') {
        this.getDynamicNewsFollowList();
      } else if(n == 'e') { //产品动态
        this.getProductDynamicFollowList();
      }
      // this.getData();
    },

    // 获取我的收藏 -- 产品信息
    getUserFollowList() {
      let params = {
        "condition": {},
        "page": 1,
        "size": ''
      };
      indexApi.getUserFollowList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list;
          this.dataList = data;
          // this.tagInfo = data && data.tagInfo;
          // this.$message.success('发送成功')
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    // 获取我的收藏 -- 活动日历
    getActivityFollowList() {
      let params = {
        "condition": {},
        "page": 1,
        "size": ''
      };
      indexApi.getActivityFollowList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list;
          this.dataList = data;
          // this.$message.success('发送成功')
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    // 获取我的收藏 -- 临床视角
    getPerspectiveFollowList() {
      let params = {
        "condition": {},
        "page": 1,
        "size": ''
      };
      indexApi.getPerspectiveFollowList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list;
          this.dataList = data;
          // this.$message.success('发送成功')
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    // 获取我的收藏 -- 动态资讯
    getDynamicNewsFollowList() {
      let params = {
        "condition": {},
        "page": 1,
        "size": ''
      };
      indexApi.getDynamicNewsFollowList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list;
          this.dataList = data;

          this.dataList.map((item)=>{
            item.coverImg = item.titleImg
          })
          // this.$message.success('发送成功')
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },
    // 获取我的收藏 -- 产品动态
    getProductDynamicFollowList() {
      let params = {
        "condition": {},
        "page": 1,
        "size": ''
      };
      indexApi.getProductDynamicFollowList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list;
          this.dataList = data;

          this.dataList.map((item)=>{
            item.coverImg = item.titleImg
          })
          // this.$message.success('发送成功')
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    // 点击收藏-取消
    changeFollow(id) {
      let type = ''
      if(this.active == 'a') {
        type = 1;
      } else if(this.active == 'b') {
        type = 2;
      } else if(this.active == 'c') {
        type = 4;
      } else if(this.active == 'd') {
        type = 3;
      } else if(this.active == 'e') {
        type = 5;
      }
      let params = {
        "dataId": id,//数据ID 
        "type": type,//类型 1产品 2活动 3资讯 4 临床视角 5产品动态
      };
      indexApi.followOrCanFollow(params).then((res)=>{
        
        // this.getProductDynamicDetail();
        if(this.active == 'a') {
          this.getUserFollowList();
        } else if(this.active == 'b') {
          this.getActivityFollowList();
        } else if(this.active == 'c') {
          this.getPerspectiveFollowList();
        } else if(this.active == 'd') {
          this.getDynamicNewsFollowList();
        } else if(this.active == 'e') {
          this.getProductDynamicFollowList();
        }
      },(err)=>{
        this.$message.success(err && err.message || '操作失败，请联系管理员！')
    })
    },


    // 收藏
    isCollection(id) {
      this.isClocActiveID = id;
      this.isClocActive = !this.isClocActive;
      // this.isClocActive ? this.clocText = '取消' : this.clocText = '收藏'
      this.changeFollow(id);

    },

    //跳转到详情
    goDetail(id,realmId,productIds){
        let url = '';
        if(this.active == 'a') { //产品信息
            this.$router.push(`product?id=${id}`)
        } else if(this.active == 'b') { //活动日历
            this.$router.push(`activity-calendar-detail?id=${id}`)
        } else if(this.active == 'c') { //临床视角
            url = '/medical-records-detail/' + id
            window.location.href = url;
        } else if(this.active == 'd') { //动态资讯
            url = '/news-details/' + id
            window.location.href = url;
        } else if(this.active == 'e') { //产品动态
            this.$router.push({
                name: 'productDetail',
                params:{ id: id},
                query: {
                    productIds: productIds,
                    producType: realmId || ''
                }
            })
        }
    }
  }
}
</script>

<style scoped lang="scss">
.collection {
  line-height: 1;
  height: 5.85rem;
  position: relative;
  .linGradual{
    width: 100%;
    height: 0.58rem;
    background: #fff;
    position: absolute;
    bottom: -0.21rem;
    right: 0;
    box-shadow: 0px -30px 30px 0px #FFFFFF;
}
  .empty-box{
      text-align: center;
      padding-top:1rem;
  }
  .top {
    display: flex;
    align-items: center;
    margin-bottom: .4rem;
    li {
      width: .88rem;
      height: .28rem;
      line-height: .28rem;
      border-radius: .04rem;
      border: solid 1px #e5e5e5;
      color: #666;
      font-size: .14rem;
      text-align: center;
      margin-right: .1rem;
      cursor: pointer;
      transition: all .3s;
      &.active {
        background: #fdebf4;
        color: #eb3c96;
        border-color: #fdebf4;
      }
    }
  }
  .content {
      height: 5.15rem;
      padding:0 0.4rem 0 0;
      overflow-x: hidden;
      overflow-y: scroll;
      &::after{
          content: '';
          height: 0.5rem;
          display: block;
      }
    
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: .5rem;
    }
    .img {
      display: block;
      width: 2.7rem;
      height: 1.4rem;
      margin-right: .4rem;
      border-radius: .08rem;
    }
    .right {
      flex: 1;
      position: relative;
      .title {
        font-size: .22rem;
        color: #333;
        margin-bottom: .2rem;
        margin-top: 2px;
        padding-right:1rem;
        line-height: 0.28rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .des {
        font-size: .14rem;
        color: #666;
        line-height: 1.6;
        margin-bottom: .2rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
        &.bottom0{
            margin-bottom: 0;
        }
      }
      .tag {
        .t {
          padding: 0 .12rem;
          height: .22rem;
          line-height: .22rem;
          background-color: #f0f0f0;
          border-radius: 2px;
          font-size: .14rem;
          color: #333;
        }
        margin-bottom: 0.2rem;
      }
      .star {
        position: absolute;
        right: 0;
        top: 2px;
        display: flex;
        align-items: center;
        font-size: .14rem;
        height: .16rem;
        line-height: 0.18rem;
        color: #333;
        background: url("~./images/star.png") left center / .16rem .16rem no-repeat;
        cursor: pointer;
        padding-left: .26rem;
        &.active{
          background: url("~./images/star-active.png") left center / .16rem .16rem no-repeat;
          // color: #EB3C96;
        }
        .icon {
          margin-right: .1rem;
          width: .16rem;
          height: .16rem;
        }
      }
    }
  }
}
</style>
